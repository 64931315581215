<template>


    
    
        <div class="row g-2 ">
          

    
                
                <div class="col-md-4 mx-auto">
     <div class="row">
      <div class="col-12">
        <div class="card">
       
          <div class="card-body">
              <br>
              <br>
           <div class="text-center">
 <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
           </div>
        
            <br>
              <br>
          </div>
        </div>
      </div>
   
    </div>
                </div>
                </div>
          
            <!-- end col -->
    
        <!--end row-->
    

</template>
