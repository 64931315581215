
<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
    <LoadingVue v-if="itemload" />
    
        <div class="row g-2" v-else>
            <div class="col-md-8 mx-auto">
                  <Notification />
    <div class="row">
    
                <div class="col-md-4">
                    <div>
    
                        <div class="row">
                            <div class="col-12" v-for="item in items" :key="item.id">
                                <div class="card" :class="{active: addclass(item.code)}" @click="selecteditems(item.code)">
    
                                    <div class="card-body p-1">
                                        <div class="form-check custom-option custom-option-basic checked">
                                            <label class="form-check-label custom-option-content" :for="item.code">
                  
                    <img :src="link+item.image" alt="" class="rounded-circle avatar-sm  me-2">
                    <span class="custom-option-body">
                      <strong class="fs-5">{{item.name}}</strong>
                    </span>
                  </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
    
    
                        <!--end row-->
    
                        <!--end offcanvas-->
                    </div>
                </div>

                
                <div class="col-md-8">
     <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Subscribe Gotv</h4>
            
          </div>
          <!-- end card header -->
          <div class="card-body">
           
            <div class="live-preview">
              <form action="javascript:void(0);">
                <div class="mb-3">
                  <label for="employeeName" class="form-label"
                    >Decoder/Smartcard Number</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="employeeName"
                    v-model="decodernumber"
                    placeholder="Decoder/Smartcard number"
                  />
                </div>
                <div class="mb-3" v-if="verify">
                  <label for="employeeUrl" class="form-label"
                    >Decoder Name</label
                  >
                  <input
                    type="url"
                    class="form-control"
                    v-model="decodername"
                    readonly
                    id="employeeUrl"
                    placeholder="Enter emploree url"
                  />
                </div>
                <div class="mb-3" v-if="verify">
                  <label for="employeeName" class="form-label"
                    >Select Plan</label
                  >
                   <select class="form-select mb-3" aria-label="Default select example" v-model="selectedplan">

    <option :value="item" v-for="item in plans"  :key="item.id">{{item.name}}</option>
    
</select>
                </div>


                 <div class="mb-3" v-if="verify && selectedplan">
                  <label for="employeeName" class="form-label"
                    >Plan Option</label
                  >
                   <select class="form-select mb-3" aria-label="Default select example" v-model="selectedsubplan">

    <option :value="item" v-for="item in subplans"  :key="item.id">For {{item.monthsPaidFor}} {{item.monthsPaidFor > 1 ? "months": "month"}} </option>
    
</select>
                </div>
                <div class="mb-3" v-if="verify && (planamount != null)">
                  <label for="employeeUrl" class="form-label"
                    >Plan Amount</label
                  >
                  <input
                    type="url"
                    class="form-control"
                    readonly
                    v-model="planamount"
                    id="employeeUrl"
                    placeholder="Enter emploree url"
                  />
                </div>
                
                <div class="text-end">
                  <button type="submit" @click="submit" class="btn btn-primary" :disabled="load">
                 <div class="spinner-border spinner-border-sm" role="status" v-if="load" > 
  <span class="sr-only">Loading...</span>
</div> Continue
                  </button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
   
    </div>
                </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
</template>





<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import LoadingVue from "./components/Loading.vue";

export default {
    page: {
        title: "CableTv",
               items: [],
        
       
        meta: [{ name: "description", content: appConfig.description }],
    },
    computed: {
   
    },
    data() {
        return {
            title: "CableTv",
             link: process.env.VUE_APP_URL,
              verify: false,
               load: false,
                     itemload: false,
             decodernumber: null,
               decodername: null,
     
        plans: [],
             selecteditem: null,
              selectedplan: null,
              selectedsubplan: null,
               subplans: null,
               planamount: null,
            items: [],
        };
    },
    name: "CableTv",
    created() {
        this.getitems();
    },
watch: {
    selecteditem: function() {

        this.verify = false;
        this.decodername = null;
        this.selectedplan = null;
        this.planamount = null;
        this.selectedsubplan = null;
    },
     decodernumber: function() {

        this.verify = false;
        this.decodername = null;
        this.selectedplan = null;
        this.planamount = null;
        this.selectedsubplan = null;
    }
,

     selectedplan: function(val) {
if (val != null) {
      this.subplans = val.availablePricingOptions;
        this.selectedsubplan = val.availablePricingOptions[0]; 
}
       
     

    },

     selectedsubplan: function(val) {

       if (val != null) {
             this.planamount = val.price;
       }
      

    }
},
    methods: {
submit(){


    if(this.verify == true){

      this.processtransaction();

    }else {

    this.verifydecoder();


    }
},
         async processtransaction() {
       this.load = true;

    await axios.post(process.env.VUE_APP_MAIN_URL + "/process/cabletv", {type: this.selecteditem, decoder: this.decodernumber, plan: this.selectedplan.code, months: this.selectedsubplan.monthsPaidFor, pamount:this.selectedsubplan.price, pname:this.selectedplan.name }).catch((error) => {

              this.load = false;

            if (error.response.status == 420) {
               Swal.fire("Error!", error.response.data.data, "error");
            }else {
                  Swal.fire("Error!", "Please make sure you have active internet or fields are correct", "error");
            }

               
                throw error;

            }).then((response) => {
                this.load = false;
                 this.$router.push({name: "transaction", params: { hash: response.data.data.data.hash}});

            })

      },




selecteditems(item){
          console.log(item);
this.selecteditem = item;

},
     addclass(item){
    
            return this.selecteditem == item;
        },

      async  getitems() {
        this.itemload = true;
  await axios.get(process.env.VUE_APP_MAIN_URL+ "/get/cabletvs").catch((error) => {
      this.itemload = false;
       
         Swal.fire("Error!", "Please Check Your Internet Connection", "error");
        throw error;

}).then((response) => {
  this.itemload =false;

    if (response.data.status == true) {
        
       this.items = response.data.data.data;
    
       
    }

})
        },




              async  verifydecoder() {
                  this.load = true;
  await axios.post(process.env.VUE_APP_MAIN_URL+ "/verify/cabletvs", {type: this.selecteditem, decoder: this.decodernumber}).catch((error) => {
      if (error.response.status == 420) {
            Swal.fire("Error!", error.response.data.data, "error");
            // console.log(error.response.data)
      }else {
              Swal.fire("Error!", "Check your internet connection", "error");
      }
       
             this.load = false;
        throw error;

}).then((response) => {

    if (response.data.status == true) {
        console.log(response.data.data.plans);
        
       this.decodername = response.data.data.name;
        this.plans = response.data.data.plans;
        this.verify = true;
    
       
    }
      this.load = false;

})
        }
    },
    components: {
        Layout,
        PageHeader,
        LoadingVue,
    },
};
</script>
<style scoped>
.active  {
  border: 2px solid green;
}
</style>